<template>
    <v-container id="store">
        <v-row>
            <v-col cols="12" md="6"  v-for="(product,i) in allProducts" :key="'product-'+i">
                <ProductItem v-bind:product="product" v-bind:sizes="productsSizesMatched[product._id]" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProductItem from '../components/ProductItem.vue';

export default {
    data(){
        return{
            allProducts: [],
            allSizes:[],

        }
    },
    components: {
        ProductItem
    },
    beforeMount: function(){
        fetch('https://rawkv3.actstudio.xyz/api/content/items/Productos', {
            method: 'GET'
        })
        .then(res => res.json())
       .then(res => this.allProducts = res)
        .catch((error) => {
            console.error('Error:', error);
        });

        fetch('https://rawkv3.actstudio.xyz/api/content/items/ProductInventory', {
            method: 'GET'
        })
        .then(res => res.json())
       .then(res => this.allSizes = res)
        .catch((error) => {
            console.error('Error:', error);
        });

    },
    computed: {
        productsSizesMatched: function(){
            let matched = {};

            this.allProducts.forEach(product => {
                matched[product._id] = this.allSizes.filter(size => size.product._id == product._id);

            });

            return matched;
        }
    }


}

</script>
